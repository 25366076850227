<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReferenceManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<HeaderBack :title="getTitle" />
							<div class="card__content--body">
								<form method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit">
									<div class="row mb-4">
										<div class="col-xl-4">
											<div class="reporting__title">{{ $t('headers.name') }}</div>
										</div>
										<div class="col-xl-4">
											<TextInput
												:title="$t('placeholder.on_ru')"
												:type="'text'"
												:name="'title'"
												:required="true"
												:errors="$v.form.title"
												:disabled="isNotEditable"
												:modelValue="form.title"
												v-on:update:modelValue="form.title = $event"
											></TextInput>
										</div>
										<div class="col-xl-4">
											<TextInput
												:title="$t('placeholder.on_kz')"
												:type="'text'"
												:name="'title_kz'"
												:required="true"
												:errors="$v.form.title_kz"
												:disabled="isNotEditable"
												:modelValue="form.title_kz"
												v-on:update:modelValue="form.title_kz = $event"
											></TextInput>
										</div>
									</div>
									<div class="row mb-4">
										<div class="col-xl-4">
											<div class="reporting__title">
												{{ $t('inputs.kato') }}
											</div>
										</div>
										<div class="col-xl-5">
											<TextInput
												:title="$t('placeholder.on_ru')"
												:type="'number'"
												:name="'kato'"
												:required="true"
												:errors="$v.form.kato"
												:disabled="isNotEditable"
												:modelValue="form.kato"
												v-on:update:modelValue="form.kato = $event"
											></TextInput>
										</div>
									</div>
									<div class="row">
										<div class="col-xl-4">
											<div class="reporting__title">{{ $t('headers.reservoirs_name') }}</div>
										</div>
										<div class="col-xl-5">
											<SelectReservoirsList
												:value="form.reservoirs"
												:required="true"
												:disabled="isNotEditable"
												:errors="$v.form.reservoirs"
												:with-title="false"
												:multiple="true"
												@input="form.reservoirs = $event"
											/>
										</div>
									</div>

									<template v-if="!isNotEditable">
										<hr class="reporting__hr" />

										<button type="submit" class="input-btn form__line--btn">
											{{ isFullFilled ? $t('commands.update') : $t('button.save') }}
										</button>
									</template>
								</form>

								<ReportManageErrorModal :message="message" :errorMessage="errorMessage" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { apiSecond } from '@/boot/axios'
import { convertObjectToFormData } from '@/formData'

import SelectReservoirsList from '@/components/catalog/SelectReservoirsList.vue'
import HeaderBack from '@/components/UI/HeaderBack.vue'
import TextInput from '@/components/components/Inputs/TextInput.vue'
import ReportManageErrorModal from '@/components/reporting-management/ReportManageErrorModal.vue'

export default {
	components: {
		SelectReservoirsList,
		HeaderBack,
		TextInput,
		ReportManageErrorModal,
	},
	props: {
		statusPage: {
			type: String,
			default: 'create',
		},
	},
	validations: {
		form: {
			title: {},
			title_kz: {},
			kato: {},
			reservoirs: {},
		},
	},
	data() {
		return {
			form: {
				title: null,
				title_kz: null,
				kato: null,
				reservoirs: [],
			},
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
		}
	},
	computed: {
		getTitle() {
			if (this.statusPage == 'show') return this.$t('handbooks.Области')
			else if (this.statusPage == 'edit') return this.$t('handbooks.Области')
			return this.$t('handbooks.Области')
		},
		isNotEditable() {
			return this.statusPage == 'show'
		},
		isFullFilled() {
			return this.statusPage === 'edit' || this.statusPage === 'show'
		},
	},
	methods: {
		onSubmit() {
			this.$v.$touch()
			if (!this.$v.$invalid) {
				document.querySelector('.preloader').classList.add('active')

				this.form.reservoirs = this.form.reservoirs.map((reservoir) => reservoir.id)

				let formData = convertObjectToFormData(this.form)

				if (this.statusPage == 'edit') {
					formData.append('_method', 'PUT')
				}

				const apiRoute = this.isFullFilled
					? `/v2/handbook-regions/${this.$route.params.id}`
					: `/v2/handbook-regions`

				apiSecond
					.post(apiRoute, formData)
					.then(() => {
						this.$router.push(`/${this.$i18n.locale}/account/hanbooks/regions`)
					})
					.catch((error) => {
						this.checkError(error)
					})
					.finally(() => {
						document.querySelector('.preloader').classList.remove('active')
					})
			}
		},
		checkError(error) {
			if (error?.response?.status > 500) {
				this.errorMessage.status = 500
				this.errorMessage.text = this.$t('system_message.500')
			} else if (error?.response?.status == 401) {
				this.errorMessage.status = 401
				this.errorMessage.text = error?.response?.data?.message
				this.$router.push('/' + this.$i18n.locale + '/login')
			} else {
				this.errorMessage.status = 422
				this.errorMessage.text = error?.response?.data?.message
			}
			this.$modal.show('ReportManageErrorModal')
		},
		parseServerData(data) {
			this.form = Object.keys(this.form).reduce((acc, key) => {
				if (data[key]) acc[key] = data[key]
				return acc
			}, {})
		},
		async showDetail() {
			if (this.isFullFilled) {
				apiSecond
					.get('/v2/handbook-regions/' + this.$route.params.id)
					.then((response) => {
						if (response.data.data) {
							this.parseServerData(response.data.data)
						}
					})
					.catch((error) => {
						this.checkError(error)
					})
			}
		},
	},
	beforeCreate() {
		if (!localStorage.token) this.$router.push('/ru/login')
	},
	async beforeMount() {
		await this.showDetail()
	},
}
</script>

<style scoped></style>
